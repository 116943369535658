import { Link } from "react-router-dom";

export default function Product404() {
  return (
    <div className="text-center">
      <h2>404</h2>
      <h3>Product not found.</h3>
      <p>
        Go back to <Link to="/">homepage</Link> or{" "}
        <Link to="/products">browse all products</Link>
      </p>
      <img
        src="/product-404.jpg"
        alt="Product not found"
        className="col-11 md:col-8 lg:col-6 xl:col-3 border-100 border-black-alpha-20 border-1"
        style={{ borderRadius: "5px" }}
      />
    </div>
  );
}
