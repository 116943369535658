import React from "react";
import Home from "../pages/Home";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Product from "../pages/products/Product";
import TopBar from "../layout/TopBar";
import Footer from "../layout/Footer";
import TopNavBar from "../layout/TopNavBar";
import Product404 from "../pages/products/Product404";
import Page404 from "../pages/Page404";
import { lazyProductsCards, lazyWithSpinner } from "../utils/utils";

const AboutUs = React.lazy(() => import("../pages/AboutUs"));
const ContactUs = React.lazy(() => import("../pages/ContactUs"));
const ProductCards = React.lazy(() => import("../pages/products/ProductCards"));
const Collections = React.lazy(() => import("../pages/Collections"));
const FleuraCollection = React.lazy(
  () => import("../pages/collections/Fleura")
);
const TempleCollection = React.lazy(
  () => import("../pages/collections/Temple")
);

export default function Router() {
  return (
    <BrowserRouter>
      <TopBar />
      <TopNavBar />
      <section id="section">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Outlet />}>
            <Route path="" element={lazyProductsCards(<ProductCards />)} />
            <Route path=":sku" element={<Product />} />
            <Route path="not-found" element={<Product404 />} />
          </Route>
          <Route path="/contact-us" element={lazyWithSpinner(<ContactUs />)} />
          <Route path="/about-us" element={lazyWithSpinner(<AboutUs />)} />
          <Route path="/collections" element={<Outlet />}>
            <Route
              path="fleura"
              element={lazyWithSpinner(<FleuraCollection />)}
            />
            <Route
              path="temple"
              element={lazyWithSpinner(<TempleCollection />)}
            />
            <Route path="" element={lazyWithSpinner(<Collections />)} />
          </Route>
          <Route path="*" element={<Page404 />} />
        </Routes>
      </section>
      <Footer />
    </BrowserRouter>
  );
}
