import React from "react";
import axiosInstance from "../utils/axios";
import { backendContextEndpoint } from "../endpoints";

type BackendContextType = {
  searchAvailable: boolean;
  serverUp: boolean;
};

const initialContext = (): BackendContextType => ({
  serverUp: false,
  searchAvailable: false,
});

export const BackendContext = React.createContext<BackendContextType>(
  initialContext()
);

type keysType = keyof BackendContextType;

const storageOperation = (key: keysType, op: "set" | "get", val?: any) => {
  if (op === "set") {
    if (val === null) throw new Error("val needed for setting op");
    let _val;
    switch (typeof initialContext()[key]) {
      case "boolean":
        _val = !val ? "0" : "1";
        break;
      default:
        _val = val;
    }
    localStorage.setItem(key, _val?.toString());
    return val;
  } else {
    let _val = localStorage.getItem(key);
    let ret: any;
    switch (typeof initialContext()[key]) {
      case "boolean":
        ret = _val === "1";
    }
    return ret;
  }
};

const retrieveFromStorage = (): BackendContextType => {
  return {
    serverUp: storageOperation("serverUp", "get"),
    searchAvailable: storageOperation("searchAvailable", "get"),
  };
};

export function BackendContextProvider(props: { children: React.ReactNode }) {
  const [state, setState] = React.useState(retrieveFromStorage());

  let intervalId = React.useRef<NodeJS.Timeout | null>(null);

  const refreshState = React.useCallback(() => {
    axiosInstance
      .get(backendContextEndpoint)
      .then((res) => {
        setState((s) => ({ ...s, serverUp: true, ...res.data }));
        storageOperation("searchAvailable", "set", res.data?.search_available);
      })
      .catch((err) => {
        console.error(err);
        setState(initialContext());
      })
      .then(() => (intervalId.current = setTimeout(refreshState, 60 * 1000)));
  }, []);

  React.useEffect(() => {
    refreshState();
    return () => {
      if (intervalId.current) clearTimeout(intervalId.current);
    };
  }, [refreshState]);

  return (
    <BackendContext.Provider value={state}>
      {props.children}
    </BackendContext.Provider>
  );
}
