import React from "react";
import { AxiosError } from "axios";
import { Toast, ToastMessageType } from "primereact/toast";
import { Messages, MessagesMessageType } from "primereact/messages";
import { ProductCardsSkeletonPage } from "../components/utils/Skeleton";
import Spinner from "../components/utils/Spinner";

export function formatError(axiosError: AxiosError<any> | null): string {
  let errorMessage = "";
  if (!!axiosError) {
    console.error(axiosError);
    const status = axiosError.response?.status;
    if (!!status && status >= 500) {
      errorMessage = "Server Error. Contact administrator.";
    } else if (!!status && status === 404) {
      errorMessage = "URL not found.";
    } else {
      const errorData = axiosError?.response?.data;
      if (!!errorData) {
        if (errorData.hasOwnProperty("message")) {
          errorMessage = errorData?.message;
        } else {
          for (const errorDataKey in errorData) {
            let newErrorMessage = "";
            if (
              errorDataKey === "non_field_errors" &&
              errorData.hasOwnProperty("non_field_errors")
            ) {
              newErrorMessage = `- Other errors: ${errorData[errorDataKey]}`;
            } else {
              if (typeof errorData[errorDataKey] === "object") {
                const firstKey = Object.keys(errorData[errorDataKey])[0];
                newErrorMessage = `${firstKey}: ${errorData[errorDataKey][firstKey]}`;
              } else {
                newErrorMessage = `- ${errorDataKey}: ${errorData[errorDataKey]}`;
              }
            }
            if (!errorMessage.includes(newErrorMessage)) {
              errorMessage += newErrorMessage + "\n";
            }
          }
        }
      } else {
        errorMessage = axiosError.message;
      }
    }
  }
  return errorMessage;
}

/**
 * Check whether the MouseEvent originated from an element
 * which is a child of the ref Element.
 * Used in overlays, etc., to close it if return value is false.
 *
 * @param e React.MouseEvent to extract the target Element
 * @param ref React.MutableRefObject<Element>
 */
export const eventElementHasParentRef = (
  e: React.MouseEvent,
  ref: React.MutableRefObject<any>
): boolean => {
  // @ts-ignore
  let parEl: Element | null | undefined = e.target?.parentElement;

  let refEl = ref.current;
  if (!refEl?.hasOwnProperty("getElement")) {
    console.error("Attempt to extract element invalid React ref.");
    return false;
  } else {
    refEl = refEl.getElement();
    for (let i = 0; i < 100; ++i) {
      // Limit loop to 100 to prevent infinite recursion

      if (!!parEl && parEl?.id === refEl.id) {
        return true;
      }
      parEl = parEl?.parentElement;
    }
  }
  return false;
};

export const imgSrcForProductBase = (baseString: string): string => {
  let logoPathBase = "/jewellery-brand-logos";
  if (baseString.includes("22K")) logoPathBase += "/bis-916.png";
  else if (baseString.includes("18K")) logoPathBase += "/bis-750.png";
  else if (baseString.includes("14K")) logoPathBase += "/bis-750.png";
  else if (baseString.includes("999")) logoPathBase += "/bis-999.png";
  else if (baseString.includes("950")) logoPathBase += "/bis-950.png";
  return logoPathBase;
};

export const wtFmt = (
  wt: number,
  dec: number = 3,
  suffix: "ct" | "g" = "g"
): string => {
  return (
    wt.toLocaleString("en-IN", {
      maximumFractionDigits: dec,
      minimumFractionDigits: dec,
    }) +
    " " +
    suffix
  );
};

export const showMessage = (
  messageRef: React.MutableRefObject<Messages | Toast | null>,
  message: ToastMessageType | MessagesMessageType
) => {
  messageRef.current?.show(message);
};

export const showError = (
  messageRef: React.MutableRefObject<Messages | Toast | null>,
  error: AxiosError,
  summary: string
) => {
  showMessage(messageRef, {
    summary: summary,
    severity: "error",
    detail: formatError(error),
    life: 5000,
  });
};

const iw = () => window.innerWidth;

export const isXs = () => 0 < iw() && iw() <= 576;
export const isSm = () => 576 < iw() && iw() <= 768;
export const isMd = () => 768 < iw() && iw() <= 992;
export const isLg = () => 992 < iw() && iw() <= 1200;
export const isXl = () => 1200 < iw();

export const lazyProductsCards = (el: React.ReactNode) => (
  <React.Suspense fallback={<ProductCardsSkeletonPage />}>{el}</React.Suspense>
);

export const lazyWithSpinner = (el: React.ReactNode) => (
  <React.Suspense fallback={<Spinner />}>{el}</React.Suspense>
);

export const queryParams = (): object => {
  const params = new URLSearchParams(window.location.search);
  let ret = {} as any;
  for (const e of params.entries()) ret[e[0]] = e[1];
  return ret;
};
