import React from "react";
import { Button, Dialog, InputText, InputTextarea } from "primereact";

type Props = {
  visible: boolean;
  sku: string;
  onHide(): void;
};

type Query = {
  name: string;
  phone: string;
  email: string;
  queryText: string;
};

export default function ProductQueryDialog(props: Props) {
  const { sku, visible, onHide } = props;
  const [query, setQuery] = React.useState<Query>({
    name: "",
    email: "",
    phone: "",
    queryText: "",
  });

  const submitClicked = (e: React.MouseEvent) => {
    e.preventDefault();
    alert("submit clicked");
  };

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={`Inquire about product - ${sku}`}
      className="col-12 md:col-4"
      modal
      blockScroll
    >
      <div>
        <InputText
          value={query.name}
          placeholder="Name"
          className="col-12 my-1"
          autoComplete="name"
          onChange={(e) =>
            setQuery((q) => ({ ...q, name: e.currentTarget.value }))
          }
        />
        <InputText
          value={query.phone}
          placeholder="Phone"
          className="col-12 my-1"
          autoComplete="tel"
          onChange={(e) =>
            setQuery((q) => ({ ...q, phone: e.currentTarget.value }))
          }
        />
        <InputText
          value={query.email}
          placeholder="Email"
          className="col-12 my-1"
          autoComplete="email"
          onChange={(e) =>
            setQuery((q) => ({ ...q, email: e.currentTarget.value }))
          }
        />
        <InputTextarea
          value={query.queryText}
          className="col-12 my-1"
          autoComplete="message"
          onChange={(e) =>
            setQuery((q) => ({ ...q, queryText: e.currentTarget.value }))
          }
          placeholder="Query"
        />
      </div>

      <Button onClick={submitClicked} label="Submit" />
    </Dialog>
  );
}
