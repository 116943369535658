import React from "react";
import { Link } from "react-router-dom";

type Img = {
  src: string;
  alt: string;
};

type DesktopNavSubItem = {
  label: string;
  link: string;
  image?: string;
  items?: DesktopNavSubItem[];
};

type NavItem = {
  img: Img;
  link: string;
  items: DesktopNavSubItem[];
};

export default function TopNavBar() {
  const menu: NavItem[] = [
    {
      img: { src: "/placeholder/navbar/bangles.jpg", alt: "Collections" },
      items: [
        {
          label: "Fleura",
          link: "/collections/fleura",
          items: [
            {
              label: "Sets",
              link: "/products/?collection_name=Fleura&prod_type_code=WGNK",
              image: "/legacy-images/fleura/set.jpg",
            },
            {
              label: "Bracelets",
              link: "/products/?collection_name=Fleura&prod_type_code=WGBR",
              image: "/legacy-images/fleura/bracelets.jpg",
            },
          ],
        },
        {
          label: "Temple",
          link: "/collections/temple",
          items: [
            {
              label: "Sets",
              link: "/products/?collection_name=Temple&prod_type_code=WGNK",
              image: "/legacy-images/temple/necklace.jpg",
            },
            {
              label: "Bracelets",
              link: "/products/?collection_name=Temple&prod_type_code=WGBR",
              image: "/legacy-images/temple/bracelet.jpg",
            },
            {
              label: "Earrings",
              link: "/products/?collection_name=Temple&prod_type_code=WGER",
              image: "/legacy-images/temple/earring.jpg",
            },
          ],
        },
        {
          label: "Office Wear",
          link: "",
          items: [
            {
              label: "Rings",
              link: "/products/?collection_name=Office+Wear&prod_type_code=WGRG",
              image: "/legacy-images/office-wear/ring.jpg",
            },
            {
              label: "Pendants",
              link: "/products/?collection_name=Office+Wear&prod_type_code=WGPD",
              image: "/legacy-images/office-wear/pendant.jpg",
            },
          ],
        },
      ],
      link: "/collections",
    },
    {
      img: { src: "/placeholder/navbar/bangles.jpg", alt: "Gold" },
      items: [
        { label: "Women's", link: "", items: [] },
        { label: "Men's", link: "", items: [] },
      ],
      link: "/gold-jewellery",
    },
    {
      img: { src: "/placeholder/navbar/earrings.jpg", alt: "Diamond" },
      items: [
        { label: "Women's", link: "", items: [] },
        { label: "Men's", link: "", items: [] },
      ],
      link: "/diamond-jewellery",
    },
    {
      img: { src: "/placeholder/navbar/mangalsutra.jpg", alt: "Platinum" },
      items: [
        { label: "Women's", link: "", items: [] },
        { label: "Men's", link: "", items: [] },
      ],
      link: "/platinum-jewellery",
    },
    {
      img: { src: "/placeholder/navbar/platinum.jpg", alt: "Solitaires" },
      items: [
        { label: "Women's Rings", link: "", items: [] },
        { label: "Men's Rings", link: "", items: [] },
      ],
      link: "/solitaires",
    },
    {
      img: { src: "/placeholder/navbar/platinum.jpg", alt: "Gifting" },
      items: [{ label: "", link: "", items: [] }],
      link: "/gifting",
    },
  ];

  const renderDesktopSubItem = (i: DesktopNavSubItem, j: number) => {
    const innerNode = (
      <>
        <span>{i.label}</span>
        {!!i?.items?.length && (
          <>
            <i className="fa fa-angle-right" />
            <ul className="overlay-img-list">
              {window.innerWidth >= 1025 &&
                i.items.map((k, l) => {
                  return (
                    <Link
                      to={k.link}
                      key={`navbar-overlay-child-li-link-key-${l}`}
                      role="li"
                      className="overlay-img-link"
                    >
                      <div>{k.label}</div>
                      {!!k.image && (
                        <div>
                          <img src={k.image} alt={k.label} />
                        </div>
                      )}
                    </Link>
                  );
                })}
            </ul>
          </>
        )}
      </>
    );
    if (i.link.length && window.innerWidth < 1025) {
      return (
        <Link
          to={i.link}
          key={`overlay-text-link-key-${j}`}
          role="li"
          className="overlay-text-link"
        >
          {innerNode}
        </Link>
      );
    } else {
      return (
        <li className="overlay-text-li" key={`overlay-text-li-key-${j}`}>
          {innerNode}
        </li>
      );
    }
  };

  return (
    <>
      <nav id="top-nav">
        <div id="mobile-navbar">
          {menu.map((m, i) => (
            <div key={`mobile-navitem-key=${i}`} className="mobile-rounded">
              <a href={m.link}>
                <img src={m.img.src} alt={m.img.alt} />
              </a>
            </div>
          ))}
        </div>
        <ul id="desktop-navbar">
          {menu.map((m, i) => (
            <li className="desktop-navbar-item" key={`nav-item-key-${i}`}>
              {m.img.alt}
              <ul className="overlay-text-list">
                {m.items.map(renderDesktopSubItem)}
              </ul>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
}
