import { Skeleton } from "primereact";

type ImageSkeletonProps = {
  width?: string;
};

export const ImageSkeleton = (props: ImageSkeletonProps) => {
  let { width } = props;
  if (!width?.length) {
    width = "5rem";
  }
  return <Skeleton width={width} className="mb-2" height="500px" />;
};

export const ProductSkeleton = () => {
  return (
    <div className="col-12 md:col-10">
      <Skeleton width="8rem" />
      <div className="grid mt-3 col-12">
        <div className="col-12 md:col-5">
          <Skeleton width="100%" height="500px" />
        </div>
        <div className="col-12 md:col-5">
          <Skeleton width="100%" />
          <Skeleton width="80%" className="my-2" />
          <Skeleton width="100%" />
          <Skeleton width="100%" height="50px" className="my-2" />
        </div>
      </div>
    </div>
  );
};

export const ProductCardSkeleton = () => {
  return (
    <div className="col-12 md:col-3 block">
      <div className="mt-2 col-12">
        <Skeleton width="6rem" />
      </div>
      <div className="mt-2 col-12">
        <div className="col-12">
          <Skeleton width="100%" />
        </div>
        <div className="col-12">
          <Skeleton width="100%" height="200px" />
        </div>
        <div className="col-12">
          <Skeleton width="50%" />
          <Skeleton width="100%" />
        </div>
      </div>
    </div>
  );
};

export const ProductCardsSkeletonPage = () => (
  <div className="col-12 md:col-10 lg:col-8 flex flex-wrap">
    <div className="col-6 md:col-4 lg:col-3">
      {[...Array(10).fill(0)].map((_, i) => (
        <ProductCardSkeleton key={`product-cards-skeleton-key-${i}`} />
      ))}
    </div>
  </div>
);
