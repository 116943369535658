import { Link } from "react-router-dom";

export default function Page404() {
  return (
    <>
      <h2>Page not found.</h2>
      <p>
        {" "}
        Go to <Link to="/">home</Link>.
      </p>
    </>
  );
}
