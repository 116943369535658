import React from "react";
import { midasUrl } from "../endpoints";
import { Link } from "react-router-dom";

export default function Home() {
  const [narrowBannerVisible, setNarrowBannerVisible] = React.useState(true);

  const hideNarrowBanner = (e: React.MouseEvent) => {
    e.preventDefault();
    setNarrowBannerVisible(false);
  };
  return (
    <div>
      <h1 style={{ display: "none" }}>Chawla Jewellers</h1>
      <div
        id="top-narrow-banner"
        className={narrowBannerVisible ? "visible" : ""}
      >
        <span>Top Narrow Banner</span>
        <i
          id="top-narrow-banner-close-icon"
          className="fas fa-times"
          onClick={hideNarrowBanner}
        />
      </div>
      <Link to="/collections/temple">
        <div id="banner-1" className="banner-div">
          <h4>Temple collection</h4>
        </div>
      </Link>
      <br />
      <div id="banner-2" className="banner-div flex-wrap md:flex-nowrap">
        <div
          id="banner-2-left"
          className="flex justify-content-center align-items-center col-12 md:col-4"
        >
          <img src="/coll-large-1.jpg" alt="coll-large-1" />
        </div>
        <div id="banner-2-center" className="flex col-12 md:col-4 px-1">
          <div className="flex-column justify-content-evenly">
            <img src="/coll-small-1.jpg" alt="coll-small-1" className="m-1" />
            <img src="/coll-small-2.jpg" alt="coll-small-2" className="m-1" />
          </div>
          <div className="flex-column">
            <img src="/coll-small-3.jpg" alt="coll-small-3" className="m-1" />
            <img src="/coll-small-4.jpg" alt="coll-small-4" className="m-1" />
          </div>
        </div>
        <div
          id="banner-2-right"
          className="flex justify-content-center align-items-center col-12 md:col-4"
        >
          <img src="/coll-large-2.jpg" alt="coll-large-1" />
        </div>
      </div>
      <br />
      <div
        className="banner-div"
        style={{ background: "#c07777", padding: ".25em 0" }}
      >
        <a href="/customised-jewellery">
          <img
            id="customised-jewellery-banner"
            src="/custom-jewellery-banner.jpg"
            alt="customised-jewellery-banner"
          />
        </a>
      </div>
      <div className="banner-div" style={{ background: "white" }}>
        <a href={midasUrl} target="_blank" rel="noreferrer">
          <img
            id="midas-banner"
            src="https://midas.chawlajewellers.in/static/django_midas/img/landing_banner_transparent.png"
            alt="Midas Gold Savings Scheme"
          />
        </a>
      </div>
    </div>
  );
}
