import { Link, useLocation } from "react-router-dom";

export default function Footer() {
  const loc = useLocation();
  const contactUsLink =
    loc.pathname !== "/contact-us" ? (
      <Link to="/contact-us">Contact us</Link>
    ) : (
      <span>Contact Us</span>
    );
  // TODO: Links:
  //  - Quick contact-us through whatsapp / call
  //  - Policy
  //  - FAQs
  //  - Size guides
  return (
    <footer id="footer">
      <div className="footer-container">
        <div className="footer-content">
          <div className="sitemap">
            <div className="sitemap-block">
              <div className="block-header">Useful Links</div>
              <ul className="block-links">
                <li role="link">{contactUsLink}</li>
                <li role="link">
                  <Link to="/about-us">About us</Link>
                </li>
              </ul>
            </div>
            <div className="sitemap-block">
              <div className="block-header">More Links</div>
              <ul className="block-links">
                <li role="link">
                  <a href="https://midas.chawlajewellers.in">
                    Midas Savings Scheme
                  </a>
                </li>
                <li role="link">
                  <Link to="/products">Products</Link>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div className="brands">
              <i className="fab fa-cc-visa" />
              <i className="fab fa-cc-mastercard" />
              <i className="fas fa-credit-card" />
              <i className="fab fa-cc-amex" />
              <i className="fab fa-google" />
              <i className="far fa-university" />
            </div>
          </div>
        </div>
        <div className="copyright">
          © 2022 Chawla Jewellers Pvt Ltd. All rights reserved.
        </div>
      </div>
    </footer>
  );
}
