import React from "react";
import "./App.css";
import "./styles/font-awesome-5.css";
import "./styles/cj/cj-storefront.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "./styles/theme/theme-darkgrey.css";
import "./styles/layout/layout-darkgrey.css";
import Router from "./hocs/Router";
import { BackendContextProvider } from "./context/BackendContext";

function App() {
  return (
    <div id="under-root">
      <BackendContextProvider>
        <Router />
      </BackendContextProvider>
    </div>
  );
}

export default App;
