/* lib */
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Image } from "primereact";
import { Toast } from "primereact/toast";

/* app */
import ProductQueryDialog from "../../components/dialogs/ProductQueryDialog";
import axiosInstance from "../../utils/axios";
import { productUrl } from "../../endpoints";
import { ProductSkeleton } from "../../components/utils/Skeleton";
import { imgSrcForProductBase, wtFmt } from "../../utils/utils";

type ProductComponent = {
  comp_type: string;
  weight: number;
  color: string;
  clarity: string;
  size: string;
  selling_rate: number;
  selling_price: number;
};

type ProductType = {
  prod_type: string;
  prod_sku: string;
  base: string;
  gross_weight: number;
  pieces: number;
  size: number | null;
  size_unit: string | null;
  design_tags: { id: number; tag: string }[];
  prod_desc: string;
  base_price: number;
  tag_price: number;
  collection: string;
  comps: ProductComponent[];
  huids: string[] | null | string;
  image: string | null;
  linked_products: string[];
  current_store: string | null;
};

const emptyProduct = (): ProductType => ({
  prod_type: "",
  prod_sku: "",
  base: "",
  gross_weight: 0,
  pieces: 0,
  size: null,
  size_unit: null,
  design_tags: [],
  prod_desc: "",
  base_price: 0,
  tag_price: 0,
  collection: "",
  comps: [],
  huids: null,
  image: "",
  linked_products: [],
  current_store: null,
});

export default function Product() {
  const { sku } = useParams();
  const nav = useNavigate();
  const [product, setProduct] = React.useState<ProductType>(emptyProduct());
  const [loading, setLoading] = React.useState(false);
  const [queryDialogVisible, setQueryDialogVisible] = React.useState(false);

  let toastRef = React.useRef<Toast | null>(null);

  React.useEffect(() => {
    if (!!sku) {
      setLoading(true);
      axiosInstance
        .get(productUrl(sku))
        .then((res) => setProduct(res.data))
        .catch((err) => {
          if (err.response.status === 404) {
            nav("/products/not-found");
          }
        })
        .then(() => setLoading(false));
    }
  }, [sku]);

  const openQueryDialog = (e: React.MouseEvent) => {
    e.preventDefault();
    setQueryDialogVisible(true);
  };

  const whatsappLinkPrefilled = (): string => {
    const selfLink = window.location.origin + "/products/" + sku;
    const text = "I'm interested in this product " + selfLink;
    return "https://wa.me/+919999159020?text=" + encodeURIComponent(text);
  };

  return (
    <>
      <div className="my-2 col-12 md:col-10 flex flex-wrap md:flex-nowrap justify-content-evenly">
        <Toast ref={toastRef} />
        {loading ? (
          <ProductSkeleton />
        ) : (
          <>
            <div className="col-12 md:col-4 flex justify-content-center">
              <Image
                id="preview-img-container"
                src={product.image ?? "/placeholder/placeholder-ring.svg"}
                className="col-12"
                preview
                alt={product.prod_sku}
              />
              {/*<Carousel*/}
              {/*  value={*/}
              {/*    product.images.length*/}
              {/*      ? product.images*/}
              {/*      : ["/placeholder/placeholder-ring.svg"]*/}
              {/*  }*/}
              {/*  showNavigators*/}
              {/*  showIndicators*/}
              {/*  itemTemplate={template}*/}
              {/*  style={{ zIndex: "9990" }}*/}
              {/*/>*/}
            </div>
            <div className="col-12 md:col-6 flex flex-column justify-content-evenly">
              <div className="col-12 md:col-6">
                <h3>{product.prod_type}</h3>
                <h3 id="product-sku">Code: {sku}</h3>
              </div>
              <br />
              <div id="product-desc" className="my-1 overflow-y-scroll">
                {product.prod_desc}
              </div>
              <div className="flex justify-content-evenly my-1">
                <div>
                  <span>Availability:</span>
                  <span>
                    {!!product.current_store ? "In stock" : "Out of stock"}
                  </span>
                </div>
                {!!product.current_store && (
                  <div>
                    <span>Store:</span>
                    <span>{product.current_store}</span>
                  </div>
                )}
              </div>
              <div className="col-12">
                <img
                  src={imgSrcForProductBase(product.base)}
                  alt={product.base}
                  style={{ width: "auto" }}
                />
              </div>
              <div id="reach-us">
                <span id="reach-us-text" className="small-caps">
                  Reach us for more info on this product:
                </span>
                <div className="flex px-2">
                  <a
                    id="product-whatsapp-link"
                    href={whatsappLinkPrefilled()}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-whatsapp" />
                    <span className="ml-1 small-caps">WhatsApp</span>
                  </a>
                  <a
                    id="product-call-link"
                    href="tel:+919999159020"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fas fa-headset" />
                    <span className="ml-1 small-caps">Call</span>
                  </a>
                </div>
              </div>
              <div className="flex flex-nowrap col-12">
                <Button
                  id="btn-product-inquiry"
                  onClick={openQueryDialog}
                  label="Submit an inquiry"
                  icon="fas fa-paper-plane"
                  className="mx-1"
                />
                <Button
                  icon="far fa-heart"
                  id="btn-product-heart"
                  className="mx-1"
                />
              </div>
            </div>
          </>
        )}
      </div>
      {!loading && (
        <div id="product-details-container" className="col-12">
          <h3>{product.prod_sku}</h3>
          <div className="grid justify-content-around col-12 md:flex-nowrap">
            <div className="col-12 md:col-6">
              <h4 className="small-caps">Basic Information</h4>
              <div className="line-item">
                <span className="label">Product Type</span>
                <span className="value">{product.prod_type}</span>
              </div>
              <div className="line-item">
                <span className="label">Metal</span>
                <span className="value">{product.base}</span>
              </div>
              <div className="line-item">
                <span className="label">Gross Weight</span>
                <span className="value">{wtFmt(product.gross_weight)}</span>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <h4 className="small-caps">Components</h4>
              <div className="components">
                {product.comps.map((c, i) => (
                  <div className="component" key={`comp-key-${i}`}>
                    <div className="line-item">
                      <span className="label">Type</span>
                      <span className="value">{c.comp_type}</span>
                    </div>
                    <div className="line-item">
                      <span className="label">Weight</span>
                      <span className="value">{wtFmt(c.weight, 2, "ct")}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {queryDialogVisible && (
        <ProductQueryDialog
          visible={queryDialogVisible}
          sku={sku ?? ""}
          onHide={() => setQueryDialogVisible(false)}
        />
      )}
    </>
  );
}
