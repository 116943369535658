import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import {
  AutoComplete,
  AutoCompleteCompleteMethodParams,
  AutoCompleteSelectParams,
  MenuItem,
  SlideMenu,
} from "primereact";
import { eventElementHasParentRef, isXl, isLg, isMd } from "../utils/utils";
import axiosInstance from "../utils/axios";
import { productListUrl } from "../endpoints";

type MenuOption = {
  label: string;
  value: string;
  info?: string;
  command?(): void;
};

type StrOption = {
  label: string;
  value: string;
  info?: string;
};

const commonSearchOptions: MenuOption[] = [
  { label: "Collections", value: "/collections", info: "" },
  { label: "Fleura", value: "/collections/fleura", info: "Collection" },
  { label: "Temple", value: "/collections/temple", info: "Collection" },
  { label: "All Products", value: "/products", info: "" },
];

export default function TopBar() {
  const loc = useLocation();
  const home = (el: React.ReactNode) =>
    loc.pathname === "/" ? <>{el}</> : <Link to="/">{el}</Link>;

  const navigate = useNavigate();

  const [navOpened, setNavOpened] = React.useState(false);
  const [searchOpened, setSearchOpened] = React.useState(false);
  const [, setLoading] = React.useState(false);

  const [searchOptions, setSearchOptions] = React.useState(commonSearchOptions);
  const [searchValue, setSearchValue] = React.useState<StrOption | null>(null);
  const [searchVisible, setSearchVisible] = React.useState(true);

  let autoCompleteRef = React.useRef<AutoComplete | null>(null);
  let dropdownInputRef = React.useRef<HTMLInputElement | null>(null);

  const onSearchItemSelect = (e: AutoCompleteSelectParams) => {
    const val = e.value;
    setSearchValue(null);
    setSearchOptions(commonSearchOptions);
    if (!!val.value) {
      navigate(val?.value);
    } else if (!!val?.command && typeof val.command === "function") {
    }
    setSearchVisible(false);
    setSearchOpened(false);
    dropdownInputRef.current?.blur();
  };

  const doSearch = (e: AutoCompleteCompleteMethodParams) => {
    const query = e.query.toLowerCase().trim();
    const filtered = commonSearchOptions.filter(
      (v) => !!v?.label && v.label?.toLowerCase().startsWith(query)
    );
    if (query.length >= 4) {
      setLoading(true);
      axiosInstance
        .get(productListUrl, { params: { prod_sku: query, in_stock: true } })
        .then((res) => {
          setSearchOptions(() => {
            if (res.data?.results?.length) {
              return res.data?.results
                .map((p: any) => ({
                  label: p?.sku,
                  value: "/products/" + p?.sku?.toString(),
                  info: "Product",
                }))
                .concat(filtered);
            }
            return filtered;
          });
        })
        .catch(() => setSearchOptions(commonSearchOptions))
        .then(() => setLoading(false));
    } else {
      setSearchOptions(filtered);
    }
  };

  const searchItemTemplate = (opt: StrOption) => (
    <div className="flex col-12 justify-content-start">
      <i
        className={
          opt?.info === "Product"
            ? "fas fa-rings-wedding"
            : "fas fa-file-invoice-dollar"
        }
        style={{ position: "relative", margin: "0 1rem" }}
      />
      <div className="ml-2">
        <div className="font-bold">{opt.label}</div>
        <small>{opt.info}</small>
      </div>
    </div>
  );

  const logoEl = home(
    <img
      src="/logo-name.png"
      height={searchOpened ? "0" : "70px"}
      width={searchOpened ? "0" : "250px"}
      alt="Chawla Jewellers"
      id="logo-img"
    />
  );

  let slideMenuRef = React.useRef<SlideMenu | null>(null);

  const overlayClicked = (e: React.MouseEvent) => {
    if (!eventElementHasParentRef(e, slideMenuRef)) {
      setNavOpened(false);
    }
  };

  /** search dropdown panel needs to be repositioned for different viewports;
   * only working solution found was to set transform: translateY(...vw) */
  const panelPositionTransform =
    isMd() || isLg()
      ? "translateX(6vw)"
      : isXl()
      ? "translateX(8vw)"
      : "translateX(2vw)";

  const menuItems: MenuItem[] = [
    {
      label: "Gold",
      items: [
        {
          label: "Women's",
          items: [
            { label: "Bangles" },
            { label: "Earrings" },
            { label: "Rings" },
            { label: "Necklaces" },
          ],
        },
        {
          label: "Men's",
          items: [{ label: "Bracelets" }, { label: "Rings" }],
        },
      ],
    },
    {
      label: "Diamond",
      items: [
        {
          label: "Women's",
          items: [
            { label: "Bangles" },
            { label: "Earrings" },
            { label: "Rings" },
            { label: "Necklaces" },
          ],
        },
        {
          label: "Men's",
          items: [{ label: "Bracelets" }, { label: "Rings" }],
        },
      ],
    },
  ];

  const openMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    setSearchOpened(false);
    setNavOpened(true);
  };

  return (
    <header id="header">
      <div id="top-bar" className={classNames({ overlaid: navOpened })}>
        <div id="logo-div" className={classNames({ hidden: searchOpened })}>
          {logoEl}
        </div>
        <div id="search" className={classNames({ visible: searchOpened })}>
          <span className="p-input-icon-left">
            <i
              id="input-search-icon"
              className="pi pi-search"
              style={{ color: "rgba(255,255,255,0.48)" }}
            />
            <AutoComplete
              ref={autoCompleteRef}
              inputRef={dropdownInputRef}
              placeholder="Search SKU, collections, etc."
              suggestions={searchOptions}
              value={searchValue}
              onChange={(e) => setSearchValue(e.value)}
              onSelect={onSearchItemSelect}
              completeMethod={doSearch}
              forceSelection
              field="label"
              itemTemplate={searchItemTemplate}
              id="topbar-search"
              autoCorrect="off"
              className={classNames({ visible: searchVisible })}
              spellCheck="false"
              onBlur={() => dropdownInputRef.current?.blur()}
              panelClassName="col-10 md:col-7 lg:col-5 xl:col-6"
              panelStyle={{ transform: panelPositionTransform }}
            />

            <i
              id="hide-search-icon"
              className="fas fa-times"
              onClick={() => setSearchOpened(false)}
            />
          </span>
        </div>
        <div id="search-icon" className={classNames({ hidden: searchOpened })}>
          <i className="fas fa-search" onClick={() => setSearchOpened(true)} />
        </div>
        <div id="menu-icon">
          <i className="fas fa-bars" onClick={openMenu} />
        </div>
      </div>
      <div
        id="menu-overlay"
        className={classNames({ visible: navOpened })}
        onClick={overlayClicked}
      >
        <i
          id="overlay-close-icon"
          className="fas fa-times"
          onClick={() => setNavOpened(false)}
        />
        <SlideMenu
          id="menu"
          className="mt-8"
          ref={slideMenuRef}
          model={menuItems}
          menuWidth={window.innerWidth - 20}
          style={{ width: "100%" }}
          viewportHeight={window.innerHeight / 2}
        />
      </div>
    </header>
  );
}
